@use "@/styles/variables" as *;

$color-input-border: $color-light-gray-2 !default;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !default;


.previewContainer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
}

.previewInput {
    padding: $space-xxs;
    transition: 0.5s all;
    flex: 1;
    margin-bottom: 0;
    width: fit-content;
    border: 1px solid $color-primary;
    border-radius: 2px;

    &:focus {
      border: 1px solid $color-primary;
    }
}

.previewCard {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    gap: $space-sm;
    margin-right: $space-md;
    padding: $space-xxs;
    width: 300px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0.25s all;
    left: -50%;
    box-shadow: $box-shadow;
    bottom: 0;
}

.visible {
    opacity: 1;
    visibility: visible;
    left: 0;
    bottom: 0
}

.close {
    margin-left: auto;
    cursor: pointer;
    padding: $space-xs;
    border: none;
    outline: none;
    transition: 0.3s scale;
    transform-origin: center;

    &:hover {
        cursor: pointer;
        scale: 1.05;
    }
}

.previewButton {
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: $space-xs;
    padding: $space-xxs !important;
    transition: 0.3s scale;
    background: $color-white !important;

    &:hover {
      cursor: pointer;
      scale: 1.1;
    }
}

.titleContainer {
    display: flex;
}

.title {
    font-size: $font-size-md;
    margin: 0;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    gap: $space-xxs;
    margin-right: $space-sm;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
}

.button {
    border: none;

    &:hover {
        cursor: pointer;
        border: 1px solid $color-black;
    }
}