@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.body {
  position: relative;
  height: 670px;
  overflow: hidden;
  background: $color-secondary-900;

  @include sm-min {
    height: 850px;
  }

  @include xl-min {
    height: 1050px;
  }
}

.description {
  position: relative;
  z-index: 1;
  font-size: $font-size-lg;
  font-weight: $font-weight-xthin;
  letter-spacing: $letter-spacing-small-text;
  color: $color-white;
  line-height: 1.2;
  padding: $space-sm $space-lg;

  mark {
    color: #FFB800;
    background: transparent;
  }

  @include sm-min {
    font-size: #{$font-size-xl - 1px};
  }
}

.descriptionFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: $space-sm $space-lg;
  border-top: 1px solid $color-white;
  width: 100%;
  min-height: 70px;
  display: flex;
  gap: $space-lg;
}

.info {
  font-size: $font-size-sm;
  font-weight: $font-weight-xthin;
  letter-spacing: $letter-spacing-small-text;
  line-height: 1.2857;
  color: $color-white;
  padding-right: $space-base;
  padding-bottom: $space-xxl;
}

.shoeImageMobile {
  position: absolute;
  z-index: 0;
}

.shoeImageDesktop {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: auto;

  @include xl-min {
    top: -100px !important;
  }
}
