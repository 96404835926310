@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.title {
  margin: $space-sm 0 $space-sm $space-base;
  letter-spacing: 1;
  font-size: $font-size-md;
  text-transform: uppercase;
}

.titleContainer {
  margin: 0 auto;
}