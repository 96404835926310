@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$field-top-margin: 7px;

.input {
  margin-bottom: $field-top-margin;
}

.button {
  margin-top: $space-base;
  margin-bottom: 30px;
}
