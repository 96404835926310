@use "@/styles/variables" as *;

.emptyOrder {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: $space-base 10px;
}

.text {
  margin-bottom: $space-base;
}
