.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;
}

.content {
  width: 100%;
  max-width: 1200px;
}

.pagesContainer {
  display: flex;
  flex-wrap: wrap;
}

.category {
  width: 25%;
  padding: 25;
}

.options {
  > div {
    margin-bottom: 12px;
  }
}

.blockLabel {
  margin-bottom: 4px;
}

.row {
  display: flex;
  gap: 7px;
}
