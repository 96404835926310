.noCategoryContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 30vh;
}

.noCategoryText {
    color: rgba($color: #000, $alpha: .5);
}

.row {
    display: flex;
}

.button {
    width: 125px;
    margin-left: 5px;
}