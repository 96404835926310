@use "@/styles/variables" as *;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: $space-md;
}

.content {
  width: 100%;
  max-width: $breakpoint-lg; // Default value.

  &.sm {
    max-width: $breakpoint-sm
  }

  &.md {
    max-width: $breakpoint-md
  }

  &.lg {
    max-width: $breakpoint-lg
  }

  &.xl {
    max-width: $breakpoint-xl
  }
}