@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border: none;
  background-color: $color-light-gray-6;

  .icon {
    width: 36px;
    margin: $space-xs;
  }

  .info {
    display: flex;
    flex-direction: column;
    text-align: left;

    .title {
      font-size: $font-size-sm;
      margin: $space-xxs 0;
      font-weight: $font-weight-heavy;
    }

    .description {
      font-size: $font-size-xxs;
      margin: 0 0 $space-xxs;
    }
  }
}