@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  position: relative;
}

.innerContainer {
  position: sticky;
  top: 0;
  height: 100vh;
}

.wrapper {
  height: 100vh;
  transform: scale(0.9);
  transition: transform 0.4s cubic-bezier(0.22, 0.01, 0.01, 0.99);
  overflow: hidden;
}

.wrapperInView {
  transform: scale(1) !important;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
