@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.locallyIconBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border: none;
  background-color: $color-light-gray-6;
  cursor: pointer;

  .shopIcon {
    width: 36px;

    // Composed of different spacings to compensate on svg spaces and center it properly.
    margin: $space-xxxs $space-xxs 0 $space-xs;
  }

  .locallyButtonInfo {
    display: flex;
    flex-direction: column;
    text-align: left;

    .locallyButtonTitle {
      font-size: $font-size-base;
      margin: $space-xxs 0;
    }

    .locallyButtonDescription {
      font-size: $font-size-xxs;
      margin: 0 0 $space-xxs;
    }
  }
}
