@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$product-image-width: 92px;

.wrapper {
  border: 1px solid $color-returns-border;
  flex: 1;
  margin-bottom: 10px;

  @include md-min {
    margin-bottom: $space-base;
  }
}

.top {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space-base;
  background-color: $color-returns-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

.body {
  background-color: $color-white;
}

.footer {
  border-top: 1px solid $color-returns-border;
  padding: $space-base $space-xs;

  @include md-min {
    padding: $space-base;
  }
}

.orderContent {
  display: flex;
  padding: $space-base $space-xs $space-xs;

  @include lg-min {
    padding: $space-base;
  }
}

.productImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 76px;
  margin-right: $space-xs;
  transition: all 0.5s;

  @include lg-min {
    width: $product-image-width;
  }
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: $space-xxxxs;

  @include md-min {
    gap: $space-xxs;
  }
}

.delivery {
  margin-top: $space-xs;
  margin-left: 0;
  padding: 0 $space-xs $space-base;

  @include md-min {
    margin-top: 0;
    padding: 0 $space-base $space-base;
    margin-left: $product-image-width + $space-xs;
  }
}

.deliveryInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 18px;
  gap: $space-xxs;
}

.totals {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-base;
}

.totalItems {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: $space-xxs;
    font-size: $font-size-sm;
  }

  strong {
    font-size: $font-size-base;
  }
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    margin-bottom: $space-xxs;
    font-size: $font-size-sm;
  }

  strong {
    font-size: $font-size-base;
  }
}

.input {
  label {
    margin-top: $space-sm;
    margin-bottom: $space-xxs;
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
