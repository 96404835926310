@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.subtitle {
  font-size: $font-size-sm;
  font-family: $body-font;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-headline;
  margin: 0;
}

.formRow {
  display: flex;
  margin: $space-xs -8px;

  span {
    color: $color-black;
    font-size: $font-size-xs;
  }

  div[class*="error"] {
    span {
      color: $color-error;
      font-size: $font-size-base;
    }
  }
}

.inputBlock {
  padding: 0 $space-xxs;
}

.nestedForm {
  width: 100%;
  margin: 15px 0;
}

.nestedFormRow {
  display: flex;
  justify-content: space-between;
}

.nestedInputBlock {
  // Subtract 8px from every input block so there's a 16px gap on the middle
  // due to `justify-content: space-between;`
  width: calc((50% - 8px));
}

.contactExplanation {
  font-size: $font-size-xs;
}

.wFull {
  width: 100%;
}

.w50 {
  width: 50%;
}

.addressLine2Btn {
  font-weight: $font-weight-bold;
  margin-top: $space-sm;
}

.error {
  display: block;
  margin-top: $space-xxs;
  color: $color-error;
}

.nameError {
  margin-top: -$space-sm;
}

.shippingMethodError {
  background: #fff0f0;
  margin-top: 10px;
  border: 1px solid $color-error;
}

.phoneWrapper {
  position: relative;
}

.tooltipBody {
  line-height: 1.36;
  margin: 0;
  font-size: $font-size-sm;

  .privacyPolicyLink {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
}

.loader {
  height: 70px;
}
