@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.footerBlock {
  padding-left: $space-sm;
}

.blockHeader {
  text-transform: none;
  font-family: $heading-font;
  font-weight: $font-weight-regular;
  font-size: $font-size-base;
  letter-spacing: $letter-spacing-small-text;

  @include lg-min {
    margin-bottom: $space-lg;

    &::after {
      content: "";
      padding-top: $space-xxs;
      border-bottom: 1px solid $color-light-gray-3;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

:is(body) .link {
  font-size: $font-size-xs;
  font-weight: $font-weight-light;
  color: $color-black;
  padding: $space-xxxxs $space-sm;
  transition: color 0.15s ease-out;
  letter-spacing: $letter-spacing-small-text;

  &:hover {
    color: $color-primary-300;
  }
}

.accordion {
  padding: 0 $space-lg;

  & > div {
    // Sretch border to right and left edges of screen.
    &::after {
      content: "";
      border-bottom: 1px solid $color-light-gray-3;
      display: block;
      position: absolute;
      left: -$space-lg;
      right: -$space-lg;
    }
  }
}
