@use "@/styles/variables" as *;

// The model "container" encompasses the entire modal, including the background overlay.
// It covers the entire viewport and is positioned at the top of the z-index stack.
.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: $modal-layer;
}

// The `.content` class represents the modal dialog box itself.
.content {
  width: fit-content;
  position: relative;
  background-color: $color-white;
  box-shadow: 0 0 3px #0004;
  transition: opacity 250ms, transform 250ms;
  max-height: calc(100vh - $space-xxxl);
  max-width: $breakpoint-md;
  overflow: auto;

  &.paneContent {
    max-height: 100%;
    transition: transform 0.25s ease-in-out;
  }
}

.modalHeader {
  position: relative;
  width: 100%;
}

.modalClose {
  position: absolute;
  right: -$space-xxs;
  font-size: $font-size-xxxl;
  border: 0;
  background: transparent;
  cursor: pointer;
}

// The `.modal` class is used to identify the modal wrapper element used for transitioning
// the modal in and out of the DOM. It is not used to style the modal itself.
.modal {
  // `.modal` needs to have some kind of property defined in order to be exported as a
  // class name from this module. Otherwise, it will be omitted from the output.
  display: block;

  // Shared transition styles for all states of the modal transitions.
  &-enter,
  &-enter-active,
  &-enter-done,
  &-exit,
  &-exit-active,
  &-exit-done {
    transition: opacity 350ms;
    z-index: $modal-layer;
    position: fixed;
  }

  // Make the "exit" transition slightly faster than the "enter" transition to get out of
  // the user's way faster. The enter transition is slightly slower to make it more
  // apparent that the state of the UI is changing and that a modal has been invoked. On
  // the other hand, closing a modal should be quick and unobtrusive as the user generally
  // knows what to expect.
  &-exit,
  &-exit-active,
  &-exit-done {
    transition: opacity 250ms;
  }

  // The "enter" state represent the initial transition state when the modal is being show
  // or added to the DOM. In here, we set all of the initial values for the modal to
  // transition _from_.
  &-enter {
    opacity: 0;

    .content {
      transform: perspective(500px) translate3d(0, 10px, -10px) rotate3d(1, 0, 0, 0.5deg);
    }
  }

  // The "enter-active" state represents the transition state when the modal is being shown
  // or added to the DOM. In here, we set all of the final values for the modal to
  // transition _to_. "enter-done" is the same as "enter-active" except that it is
  // persisted in the DOM after the transition has completed.
  &-enter-active,
  &-enter-done {
    opacity: 1;
    transition: 250ms;

    .container {
      pointer-events: inherit;
    }

    .content {
      transform: perspective(500px) translate3d(0, 0, 0) rotate3d(1, 0, 0, 0);
    }
  }

  // The "exit" state represent the initial transition state when the modal is being
  // hidden or removed from the DOM. In here, we set all of the initial values for the
  // modal to transition _from_.
  &-exit {
    opacity: 1;

    .content {
      transform: perspective(500px) translate3d(0, 0, 0) rotate3d(1, 1, 1, 0);
    }
  }

  // The "exit-active" state represents the transition state when the modal is being
  // hidden or removed from the DOM. In here, we set all of the final values for the modal
  // to transition _to_. "exit-done" is the same as "exit-active" except that it is
  // persisted in the DOM after the transition has completed until, of course, the DOM
  // element is removed.
  &-exit-active,
  &-exit-done {
    opacity: 0;

    .container {
      pointer-events: none;
    }

    .content {
      transform: perspective(500px) translate3d(0, 10px, -10px) rotate3d(1, 1, 1, 0.5deg);
    }
  }
}

// The `.pane` class is used to identify the pane modal wrapper element used for transitioning
// the pane modal in and out of the DOM. It is not used to style the pane modal itself.
.pane {
  // `.pane` needs to have some kind of property defined in order to be exported as a
  // class name from this module. Otherwise, it will be omitted from the output.
  display: block;

  // Shared transition styles for all states of the pane modal transitions.
  &-left,
  &-right,
  &-enter,
  &-enter-active,
  &-enter-done,
  &-exit,
  &-exit-active,
  &-exit-done {
    z-index: $modal-layer;
    height: 100%;
    position: fixed;
    top: 0;
    transition: transform 0.3s ease-in-out;
    align-items: normal;
  }

  // This '.pane-right' class is used to identify the initial positioning of the
  // pane modal to the right.  It renders the pane modal 100% off-screen to the right.
  &-right {
    justify-content: flex-end;

    .paneContent {
      transform: translateX(100%);
    }
  }

  // This '.pane-left' class is used to identify the initial positioning of the
  // pane modal to the left.  It renders the pane modal -100% off-screen to the left.
  &-left {
    justify-content: flex-start;

    .paneContent {
      transform: translateX(-100%);
    }
  }

  // The "enter-active" state represents the transition state when the pane modal is being shown
  // or added to the DOM. In here, we set all of the final values for the pane modal to
  // transition _to_. "enter-done" is the same as "enter-active" except that it is
  // persisted in the DOM after the transition has completed.
  &-enter-active,
  &-enter-done {
    .container {
      pointer-events: inherit;
    }

    .content {
      transform: translateX(0);
    }
  }

  // The "exit" state represent the initial transition state when the pane modal is being
  // hidden or removed from the DOM. In here, we set all of the initial values for the
  // pane modal to transition _from_.
  &-exit {
    .content {
      transform: translateX(0);
    }
  }

  // The "exit-active" state represents the transition state when the pane is being
  // hidden or removed from the DOM. In here, we set all of the final values for the pane
  // modal to transition _to_. "exit-done" is the same as "exit-active" except that it is
  // persisted in the DOM after the transition has completed until, of course, the DOM
  // element is removed.
  &-exit-active,
  &-exit-done {
    opacity: 1;

    .container {
      pointer-events: none;
    }

    .pane-right .content {
      transform: translateX(100%);
    }

    .pane-left .content {
      transform: translateX(-100%);
    }
  }

  // Sets the modal to be transparent when the pane modal is not active.
  &-exit-done {
    opacity: 0;
  }
}

.fullScreen {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}
