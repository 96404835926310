@use "@/styles/utils" as *;

.hidden {
  display: none;
}

.xxs {
  @include xxs {
    display: contents;
  }
}

.xs {
  @include xs {
    display: contents;
  }
}

.sm {
  @include sm {
    display: contents;
  }
}

.md {
  @include md {
    display: contents;
  }
}

.lg {
  @include lg {
    display: contents;
  }
}

.xl {
  @include xl {
    display: contents;
  }
}
