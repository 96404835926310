@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.skeletonLoader {
  min-height: $font-size-lg;
  border-radius: 5px;

  margin-bottom: 10px;
}

.recentOrder {
  width: 100%;
  padding: 10px 0;
}

.section {
  padding: 10px;

  @include md-min {
    padding: 10px $space-base;
  }
}

.actionButtons {
  .skeletonLoader {
    min-height: $space-lg;
  }
}

.orderInfoSection {
  display: flex;
}

.imageContainer {
  flex: 1;

  margin-right: $space-sm;

  .skeletonLoader {
    min-height: $space-xxxxl;
  }
}

.orderInfo {
  flex: 2;
}

.infoTitle {
  margin-right: 5px;
}

.infoRow {
  font-size: $font-size-sm;
  line-height: 1.4;

  &:not(:last-child) {
    margin-bottom: $space-xxxs;
  }

  .skeletonLoader {
    min-height: $space-lg;
  }
}

.trackingLink {
  font-size: $font-size-sm;
  line-height: 1.4;
  font-weight: $font-weight-bold;
  text-decoration: underline;
}

.header {
  margin: 0 0 10px;
  text-transform: capitalize;
  font: $font-weight-bold $font-size-base $body-font;
  letter-spacing: 1;
}

.button {
  width: 100%;
}

.link {
  display: block;

  &:not(:last-child) {
    margin-bottom: $space-xs;
  }
}

.orderSummary {
  margin-top: 20px;
  padding: 10px 0;
  border-top: 1px solid $color-returns-border;

  .row {
    display: flex;
    justify-content: space-between;

    margin-bottom: $space-md;

    .item {
      .label {
        font-size: $font-size-sm;
        line-height: 1.4;
      }

      .value {
        font-weight: $font-weight-bold;
      }

      &:last-child {
        text-align: right;
      }

      min-width: 25%;
    }
  }
}

:is(body) .fullHistoryLink {
  font-weight: $font-weight-bold;
  text-decoration: underline;
}
