@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.title {
  text-transform: none;
  font-family: $heading-font;
  font-weight: normal;
  font-size: $font-size-base;
  letter-spacing: $letter-spacing-small-text;
  margin-top: $space-sm;
  margin-bottom: $space-sm;
  white-space: nowrap;
}

.fieldGroup {
  display: flex;
  position: relative;
}

.emailInput,
.emailError {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $color-light-gray-3;
  font-size: $font-size-xs;
  font-weight: $font-weight-light;
  letter-spacing: $letter-spacing-small-text;
  min-width: 200px;
  border-radius: 0;
  padding: $space-base 0 $space-xxxs 0;
  margin-bottom: 10px;

  &::placeholder {
    color: $color-black;
  }

  @include lg-max {
    width: 100%;
  }
}

.joinButton {
  padding: 0 $space-sm;
  border-radius: 0;
  background-color: transparent !important;
  border: none !important;
  color: $color-secondary-900 !important;
  font-size: 1.75rem !important;
  position: absolute;
  top: 0;
  min-width: unset !important;
  right: 10%;

  &:hover,
  &.hover {
    background-color: $color-black !important;
    color: $color-white!important;

    .spinner svg {
      color: $color-white;
    }
  }
}
