@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.headerWrapper {
  margin: $space-sm;
}

.headerContainer {
  background-color: $color-gray-1;
  padding: $space-sm;
  margin: 0;

  .headerTitle {
    margin: 0;
    font-weight: $font-weight-regular;
    padding-right: $space-lg;
    font-size: $font-size-md;
  }
}

.closeIcon {
  margin-right: $space-sm;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $space-xxs $space-sm;
  padding: $space-xxs $space-sm;
  min-width: 450px;

  @include sm-max {
    min-width: auto;
  }

  .title {
    margin: 0;
  }

  .description {
    text-align: center;
    margin-top: $space-xs;
  }

  .closeButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    margin-top: $space-md;
    text-align: center;
    border: none;
    padding: $space-xxs $space-sm;
    background-color: $color-primary;
  }
}
